import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import dayjs from 'dayjs';
import { find, findIndex, range } from 'lodash';
import React, { FC, useState } from 'react';
import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import api, { getToken } from '../../api';
import { ApiHcp } from '../../api/hcp';
import { ApiHospital } from '../../api/hospital';
import { ApiOrder, ApiOrderProduct } from '../../api/order';
import { ApiProduct } from '../../api/product';
import { hasRole, useProfile } from '../../lib/auth';
import { Button, Confirm, DropdownOption, Form, Grid, Message, Modal, PageHeader, Segment, toast } from '../../RbKit';
import Disclaimer from './Disclaimer';
import styles from './styles.module.scss';
import { useTrans } from '../../lib/trans';

const STATUS = [{
    text: 'Completato',
    value: 2,
}, {
    text: 'Cancellato',
    value: 3,
}]

interface OrderEditProps extends RouteComponentProps<{ id?: string }> {
}

const OrderEditView: FC<OrderEditProps> = ({ history, match }): JSX.Element => {
    const { id } = match.params;
    const [ hospitalMessage, setHospitalMessage ] = useState<boolean>(false);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ hcps, setHcps ] = useState<ApiHcp[]>();
    const [ availableModal, setAvailableModal ] = useState<boolean | string>(false);
    const [ errors, setErrors ] = useState<any>({});
    const [ step, setStep ] = useState<number>(1);
    const [ dStep, setDStep ] = useState<number>(1);
    const [ order, setOrder ] = useState<Partial<ApiOrder>>({});
    const [ products, setProducts ] = useState<ApiProduct[]>([]);
    const [ hospitals, setHospitals ] = useState<ApiHospital[]>([]);
    const [ limitReached, setLimitReached ] = useState<boolean | ApiProduct>(false);
    const [ limitHospitals, setLimitHospitals ] = useState<number[]>([]);
    const [ hcp, setHcp ] = useState<ApiHcp>();
    const profile = useProfile();
    const __ = useTrans('orders');

    useEffect(() => {
        dispatchEvent(new CustomEvent('clear-logout'));
    }, [step]);

    useEffect(() => {
        setHcp(profile?.hcp);

        if (hasRole(profile, 'PJP')) {
            setDStep(4);
        }
    }, [profile]);

    useEffect(() => {
        if (id) {
            api.getOrder(id).then(({ data }) => {
                setOrder(data);
                setStep(4);
            }).catch(() => {
                toast(__('Order not found'), 'error');
                history.push('/orders');
            });
        }
    }, [id, history]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setOrder({
            ...order,
            [name]: value,
        });
    }

    const handleAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        if (query.length < 3) return;
        api.autocompleteProducts(query).then(({ data }) => {
            setProducts(data.data);

            callback(data.data.map((o) => ({
                text: `${o.name}${o.form ? ` - ${o.form}` : ''}${o.isActive && o.isAvailable ? '' : ` (${__('sample not available')})`}`,
                value: o.id,
            })));
        });
    }

    const handleAutoCompleteHcp = (query: string, callback: (options: DropdownOption[]) => void) => {
        if (query.length < 3) return;
        api.listHcps({ query }).then(({ data }) => {
            setHcps(data.data);

            callback(data.data.map((o) => ({
                text: `${o.name} (${o.uci})`,
                value: o.id,
            })));
        });
    }

    const handleHcp = (hcpId: number): void => {
        const hcpObject = find(hcps, { id: hcpId }) as ApiHcp;
        if (!hcpObject) return;
        setHcp(hcpObject);
    }

    const addProduct = (productId: number): void => {
        const product = find(products, { id: productId });
        if (!product) return;
        let cont: boolean = true;
        
        if (product.isActive) {
            if (product.dosages.length === 1) {
                const hasDosage = find(order.products || [], { productDosageId: product.dosages[0].id || 0 });
                if (hasDosage) {
                    cont = false;
                }
            }

            if (cont && product.isAvailable) {
                let index = 0;

                (order.products || []).map((op) => {
                    if (op.productDosageId === product.dosages[index].id) {
                        index = index + 1;
                    }
                });

                if (!product.dosages[index]) {
                    setLimitReached(product);
                    return;
                }

                setOrder({
                    ...order,
                    products: [
                        ...(order.products || []), {
                            productId: product.id,
                            product,
                            productDosageId: product.dosages[index].id || 0,
                            productDosage: product.dosages[index],
                            amount: 1,
                        }
                    ],
                });

                if (product.hospitals) {
                    setLimitHospitals([
                        ...limitHospitals,
                        ...product.hospitals.map(o => o.id as number),
                    ]);
                }
            }
        } else if (product.availableMessage) {
            setAvailableModal(product.availableMessage);
        }
    }

    const handleProduct = (index: number, { name, value }: any): void => {
        if (!order || !order.products) return;
        const newProducts: any = [ ...order.products ];
        newProducts[index][name] = value;

        if (name === 'productDosageId') {
            const product = find(products, { id: newProducts[index].productId });
            if (product) {
                const dosage = find(product.dosages, { id: value });
                if (dosage) {
                    newProducts[index].productDosage = dosage;
                }
            }
        }

        setOrder({ ...order, products: newProducts });
    }

    const removeProduct = (index: number): void => {
        if (!order || !order.products) return;
        const newProducts: any = [ ...order.products.filter((o, i) => i !== index) ];
        setOrder({ ...order, products: newProducts });
        setLimitReached(false);
    }

    const handleHospitalAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listHospitals({ query }).then(({ data }) => {
            setHospitals(data.data);

            callback(data.data.map((o) => ({
                text: `${o.name} - ${o.address}, ${o.city}`,
                label: <span>{o.name} - <small style={{ color: '#b0b0b0' }}>{o.address}, {o.city}</small></span>,
                value: o.id,
            })));
        });
    }

    const handleHospital = (hospitalId: number): void => {
        const hospital = find(hospitals, { id: hospitalId });
        if (!hospital) return;

        if (limitHospitals.length > 0 && !limitHospitals.includes(hospitalId)) {
            setHospitalMessage(true);
        } else {
            setHospitalMessage(false);
        }

        setOrder({
            ...order,
            hospital: hospital.name,
            address: hospital.address,
            zipcode: hospital.zipcode,
            city: hospital.city,
        });
    }

    const handleHcpInput = ({ name, value }: any): void => {
        if (!hcp) return;
        setHcp({
            ...hcp,
            [name]: value,
        });
    }

    const save = (status?: number, type?: string): void => {
        if (isLoading) return;
        setErrors({});
        setIsLoading(true);
        if (!hcp && !order.hcp) return;

        api.putOrder({
            ...order,
            status: status || 0,
        }, (hcp || order.hcp) as ApiHcp, type).then(({ data }) => {
            setIsLoading(false);
            toast(__('Order saved succesfully'));
            if (type === 'wet') {
                (window as any).open(`${process.env.REACT_APP_API_BASE_URL}orders/${data.id}/download?_token=${getToken()}`, '_blank').focus();
            }
            history.push('/orders');
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast(__('Something went wrong', ''), 'error')
        });
    }

    const checkProductLimits = () => {
        setLimitReached(false);
        setErrors({});
        setIsLoading(true);

        api.checkProductLimits({
            ...order,
            hcpId: ((hcp || order.hcp) as ApiHcp).id,
        }).then(() => {
            setStep(3);
            setIsLoading(false);
        }).catch((err) => {
            const dosage = find(order.products, { productDosageId: err.response.data.errors.limit });
            if (dosage) {
                setLimitReached(dosage.product);
            }
            setIsLoading(false);
        });
    }

    if (!id && dStep <= 4) {
        return <Disclaimer onUpdate={(n: number) => setDStep(n)} />
    }

    return (<div className="container-small">
        <PageHeader
            title={order.id ? order.number : __('New order')}
            breadcrumb={{
                '/orders': __('Orders'),
                [order.id ? `/orders/${order.id}/edit` : '/orders/create']: order.id ? 'Panoramica ordini' : 'Nuovo ordine'
            }}
        />
        <div className={styles.wizard}>
            <div>
                <div
                    className={`${styles.step} ${step >= 1 ? styles.active : ''} ${step >= 2 ? styles.done : ''}`}
                    onClick={() => setStep(!order.id && step > 1 ? 1 : step)}
                >
                    <div>1</div>
                    <span>{__('Personal information')}</span>
                </div>
            </div>
            <div>
                <div
                    className={`${styles.step} ${step >= 2 ? styles.active : ''} ${step >= 3 ? styles.done : ''}`}
                    onClick={() => setStep(!order.id && step > 2 ? 2 : step)}
                >
                    <div>2</div>
                    <span>{__('Products', 'products')}</span>
                </div>
            </div>
            <div>
                <div
                    className={`${styles.step} ${step >= 3 ? styles.active : ''} ${step >= 4 ? styles.done : ''}`}
                    onClick={() => setStep(!order.id && step > 2 ? 2 : step)}
                >
                    <div>3</div>
                    <span>{__('Hospital', 'hospitals')}</span>
                </div>
            </div>
            <div>
                <div className={`${styles.step} ${step >= 4 ? styles.active : ''}`}>
                    <div>4</div>
                    <span>{__('Overview')}</span>
                </div>
            </div>
        </div>

        <Form>
            {hospitalMessage && (<Message
                error
                content={__('Some of the samples youve requested are not available for this hospital')}
            />)}

            {step === 1 && (
                <Segment>
                    <h2 style={{ marginBottom: 5 }}>{__('Personal information')}</h2>
                    <p>{__('Please check your details and fill out any missing information')}</p>
                    {hasRole(profile, 'PJP') && (
                        <Form.Dropdown
                            label={__('Select an HCP (search for name or ID)')}
                            autoComplete={handleAutoCompleteHcp}
                            onChange={({ value }: any) => handleHcp(value)}
                        />
                    )}
                    <Form.Input
                        className={styles.noBorder}
                        label={__('Name', 'hcps')}
                        required
                        readOnly
                        value={hcp?.name || ''}
                    />
                    <Form.Input
                        className={styles.noBorder}
                        label={__('Registration no.', 'hcps')}
                        required
                        readOnly
                        value={hcp?.uci || ''}
                    />
                    <Form.Input
                        className={styles.noBorder}
                        label={__('Date')}
                        required
                        readOnly
                        value={dayjs().format('DD-MM-YYYY')}
                    />
                    <Form.Input
                        error={errors.address}
                        label={__('Personal address')}
                        name="address"
                        onChange={handleHcpInput}
                        required
                        className={styles.noBorder}
                        readOnly
                        value={hcp?.address || ''}
                    />
                    <Grid.Row>
                        <Grid.Column md={4}>
                            <Form.Input
                                error={errors.zipcode}
                                label={__('Personal zipcode')}
                                name="zipcode"
                                onChange={handleHcpInput}
                                required
                                className={styles.noBorder}
                                readOnly
                                value={hcp?.zipcode || ''}
                            />
                        </Grid.Column>
                        <Grid.Column md={8}>
                            <Form.Input
                                error={errors.city}
                                label={__('Personal city')}
                                name="city"
                                onChange={handleHcpInput}
                                required
                                className={styles.noBorder}
                                readOnly
                                value={hcp?.city || ''}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            onClick={() => setDStep(1)}
                            label={__('Go back')}
                        />
                        <Button
                            primary
                            label={__('Continue')}
                            disabled={!hcp?.address || !hcp?.zipcode || !hcp?.city}
                            onClick={() => setStep(2)}
                        />
                    </div>
                </Segment>
            )}
            {step === 2 && (
                <Segment>
                    <h2 style={{ marginBottom: 5 }}>{__('Products')}</h2>
                    <p>{__('Select sample products')}</p>

                    {limitReached !== false && (<Message
                        error
                        content={`Hai raggiunto il limite di campioni annuali per ${(limitReached as ApiProduct).name}`}
                    />)}

                    <Form.Dropdown
                        label={__('Add samples (start by typing at least 3 characters)')}
                        autoComplete={handleAutoComplete}
                        onChange={({ value }: any) => addProduct(value)}
                    />

                    {order.products?.map((op, index) => {
                        const dosage = op.product.dosages.find((oo) => oo.id === op.productDosageId);

                        return (<div
                            key={index}
                            className={styles.product}
                        >
                            <div className={styles.productFields}>
                                <div className={styles.sample}>
                                    <span>{op.product.name} {op.product.form}</span>
                                    {op.product.link && op.product.link.includes('https:') && (<>
                                        <a
                                            href={op.product.link}
                                            rel="noopenerer noreferrer"
                                            target="_blank"
                                        >
                                            {__('Details & dosage calc.')}
                                        </a><br />
                                    </>)}
                                    {op.product.file && (<>
                                        <a
                                            href={op.product.file}
                                            rel="noopenerer noreferrer"
                                            target="_blank"
                                        >
                                            {op.product.fileName} (PDF)
                                        </a><br />
                                    </>)}
                                    <p>
                                        In caso di monitoraggio addizionale &#9660;, fare riferimento ad RCP di Prodotto
                                    </p>
                                </div>
                                <div className={styles.dosage}>
                                    <Form.Dropdown
                                        label={__('Dosage')}
                                        name="productDosageId"
                                        onChange={(data) => handleProduct(index, data)}
                                        options={op.product.dosages.map((o) => ({
                                            text: o.dosage,
                                            value: o.id,
                                            disabled: op.product.dosages.length !== 1 && order.products?.map((oo) => oo.productDosageId).includes(o.id as number),
                                        }))}
                                        value={op.productDosageId}
                                    />
                                </div>
                                <div className={styles.amount}>
                                    <Form.Dropdown
                                        label={__('Amount')}
                                        name="amount"
                                        options={range(1, (dosage?.maxAmount ? dosage?.maxAmount : (dosage?.classification === 'A' ? 2 : 4)) + 1).map((o) => ({
                                            text: `${o}`,
                                            value: o,
                                        }))}
                                        onChange={(data) => handleProduct(index, data)}
                                        value={op.amount}
                                    />
                                </div>
                                <Confirm
                                    trigger={<Button
                                        trigger
                                        icon={faTrashAlt as any}
                                    />}
                                    content="Sei sicuro di voler rimuovere questo campione dal tuo ordine?"
                                    onConfirm={() => removeProduct(index)}
                                />
                            </div>
                            <div className={styles.info}>
                            {__('Healthcare professionals are asked to report any suspected adverse reactions and or any special situations related to the use of our product (such as pregnancy, breastfeeding, product complaint, misuse, overdose, abuse, lack of efficacy, interactions, medication error, occupational exposure). For reporting, you can click on this link')}: <a href="https://www.roche.it/chi-siamo/business/pharma/per-un-uso-sicuro-dei-medicinali" target="_blank" rel="noopenerer noreferrer">safety reporting page</a>
                            </div>
                            {op.product.information && (
                                <div className={styles.info}>
                                    {op.product.information}
                                </div>
                            )}
                        </div>);
                    })}

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            onClick={() => setStep(1)}
                            label={__('Go back')}
                        />
                        <Button
                            primary
                            label={__('Continue')}
                            disabled={!order.products ||
                                order.products.length <= 0
                            }
                            onClick={() => checkProductLimits()}
                        />
                    </div>
                </Segment>
            )}
            {step === 3 && (
                <Segment>
                    <h2 style={{ marginBottom: 5 }}>Ospedale</h2>
                    <p>Selezionare un ospedale a cui consegnare i campioni</p>
                    <Form.Dropdown
                        label="Ospedale"
                        defaultOption={order.hospital}
                        autoComplete={handleHospitalAutoComplete}
                        onChange={({ value }: any) => handleHospital(value)}
                        defaultValue={order.hospital || ''}
                        required
                    />
                    {/* <Form.Input
                        label="Informazioni aggiuntive sulla consegna"
                        name="comment"
                        onChange={handleInput}
                        value={order.comment || ''}
                    /> */}
                    <Form.Input
                        className={styles.noBorder}
                        error={errors.address}
                        label="Indirizzo"
                        name="address"
                        onChange={handleInput}
                        required
                        readOnly
                        value={order.address || ''}
                    />
                    <Grid.Row>
                        <Grid.Column md={4}>
                            <Form.Input
                                className={styles.noBorder}
                                error={errors.zipcode}
                                label="Codice postale"
                                name="zipcode"
                                onChange={handleInput}
                                required
                                readOnly
                                value={order.zipcode || ''}
                            />
                        </Grid.Column>
                        <Grid.Column md={8}>
                            <Form.Input
                                className={styles.noBorder}
                                error={errors.city}
                                label="Città"
                                name="city"
                                onChange={handleInput}
                                required
                                readOnly
                                value={order.city || ''}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            onClick={() => setStep(2)}
                            label={__('Go back')}
                        />
                        <Button
                            primary
                            label={__('Continue')}
                            disabled={!order.hospital || !order.hospital || !order.address || !order.zipcode || !order.city}
                            onClick={() => setStep(4)}
                        />
                    </div>
                </Segment>
            )}
            {step === 4 && (
                <Segment isLoading={isLoading}>
                    <h2 style={{ marginBottom: order.id ? 20 : 5 }}>Panoramica ordini</h2>
                    {!order.id && <p>Controlla il tuo ordine e procedi cliccando effettua ordine.</p>}

                    {(profile?.role === 'Administrator') && (
                        <Form.Dropdown
                            options={STATUS}
                            label="Current status"
                            value={order.status}
                            onChange={({ value }: any) => {
                                handleInput({ name: 'status', value });
                                save(value);
                            }}
                        />
                    )}

                    <div className={styles.overview}>
                        <div className={styles.col}>
                            <div className={styles.block}>
                                <h3>Informazione personale</h3>
                                <p>
                                    <b>Nome</b>
                                    <span>{profile?.hcp?.name || hcp?.name || order.hcp?.name}</span>
                                </p>
                                <p>
                                    <b>Data</b>
                                    <span>{dayjs(order.createdAt).format('DD-MM-YYYY')}</span>
                                </p>
                            </div>
                            <div className={styles.block}>
                                <h3>Ospedale</h3>
                                <p>
                                    <b>Nome</b>
                                    <span>{order.hospital}</span>
                                </p>
                                <p>
                                    <b>Indirizzo</b>
                                    <span>{order.address}</span>
                                </p>
                                <p>
                                    <b>Cap</b>
                                    <span>{order.zipcode}</span>
                                </p>
                                <p>
                                    <b>Città</b>
                                    <span>{order.city}</span>
                                </p>
                                {/* {order.comment && (
                                    <p>
                                        <span style={{ fontStyle: 'italic' }}>
                                            {order.comment}
                                        </span>
                                    </p>
                                )} */}
                            </div>
                        </div>
                        <div className={styles.col}>
                            <div className={styles.block}>
                                <h3>Campioni Gratuiti</h3>
                                {order.products?.map((op, index) => (
                                    <div key={`op-${index}`} style={{ marginBottom: '1rem' }}>
                                        <p>
                                            <b>Prodotto</b>
                                            <span>{op.product.name} {op.product.form}</span>
                                        </p>
                                        <p>
                                            <b>Dosaggio</b>
                                            <span>{op.productDosage.dosage}</span>
                                        </p>
                                        <p>
                                            <b>Quantità</b>
                                            <span>{op.amount}</span>
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {!order.id && (<>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <Button
                                onClick={() => setStep(3)}
                                label={__('Go back')}
                            />
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                                <Button
                                    primary
                                    label={__('Place order')}
                                    disabled={hospitalMessage}
                                    onClick={() => save(0)}
                                />
                            </div>
                        </div>
                    </>)}
                </Segment>
            )}
        </Form>
        <Modal
            footer={(
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        label="Ok"
                        primary
                        onClick={() => setAvailableModal(false)}
                    />
                </div>
            )}
            header={__('Sample not available')}
            onClose={() => setAvailableModal(false)}
            open={availableModal !== false}
            size="small"
        >
            {availableModal}
        </Modal>
    </div>);
}

export default OrderEditView;
