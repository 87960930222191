import { find } from 'lodash';
import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Checkbox, DropdownOption, Form, Grid, PageHeader, Segment, toast } from '../../../RbKit';
import api from '../../../api';
import { ApiProduct } from '../../../api/product';
import ChangeHandler from '../../../components/ChangeHandler';
import styles from './styles.module.scss';
import { ApiUser } from '../../../api/user';
import { ApiHospital } from '../../../api/hospital';
import { useTrans } from '../../../lib/trans';

interface ProductEditProps extends RouteComponentProps<{ id?: string }> {
}

const ProductEditView: FC<ProductEditProps> = ({ history, match }) => {
    const { id } = match.params;
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const [ hospitals, setHospitals ] = useState<ApiHospital[]>([]);
    const [ product, setProduct ] = useState<Partial<ApiProduct>>({
        classification: 'A',
    });
    const [ changesMade, setChangesMade ] = useState<boolean>(false);
    const [ file, setFile ] = useState<File>();
    const [ users, setUsers ] = useState<ApiUser[]>([]);
    const __ = useTrans('products');

    useEffect(() => {
        api.listUsers({ limit: 999 }).then(({ data }) => {
            setUsers(data.data);
        });
    }, []);

    const fetch = useCallback(() => {
        setChangesMade(false);
        if (id) {
            setIsLoading(true);
            api.getProduct(parseInt(id)).then(({ data }) => {
                setProduct(data);
                setIsLoading(false);
            });
        }
    }, [id])

    useEffect(() => {
        if (!id) {
            setIsLoading(false);
            setProduct({
                dosages: [{
                    materialNumber: '',
                    dosage: '',
                    maxAmount: 0,
                    classification: 'A',
                    commercializationDate: '',
                }],
            });
        }

        fetch();
    }, [id, fetch]);

    const handleInput = ({ name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        setProduct({
            ...product,
            [name]: value,
        });
    }

    const handleDosageInput = (index: number, { name, value }: { [key: string]: any }): void => {
        setChangesMade(true);
        const newDosages: any[] = [...(product.dosages || [])];
        newDosages[index][name] = value;
        setProduct({
            ...product,
            dosages: newDosages,
        });
    }

    const addDosage = (): void => {
        setChangesMade(true);
        setProduct({
            ...product,
            dosages: [
                ...(product.dosages || []),
                {
                    dosage: '',
                    maxAmount: 0,
                    classification: 'A',
                    commercializationDate: '',
                }
            ],
        });
    }

    const removeDosage = (index: number): void => {
        if (!product.dosages) return;
        setChangesMade(true);
        setProduct({
            ...product,
            dosages: product.dosages.filter((o, i) => i !== index),
        });
    }

    const handleHospitalAutoComplete = (query: string, callback: (options: DropdownOption[]) => void) => {
        api.listHospitals({ query }).then(({ data }) => {
            setHospitals(data.data);

            callback(data.data.map((o) => ({
                text: o.name,
                value: o.id,
            })));
        });
    }

    const handleHospital = (hospitalId: number): void => {
        setChangesMade(true);
        const h = find(hospitals, { id: hospitalId });
        if (!h) return;

        setProduct({
            ...product,
            hospitals: [...(product.hospitals || []), h],
        });
    }

    const removeHospital = (hospitalId?: number): void => {
        if (!hospitalId || !product.hospitals) return;
        setChangesMade(true);
        setProduct({
            ...product,
            hospitals: product.hospitals.filter(o => o.id !== hospitalId),
        });
    }

    const deleteFile = (): void => {
        api.putProduct({ ...product, hasFile: 0 }).then(() => {
            setProduct({
                ...product,
                file: undefined,
            });
            toast('File deleted succesfully');
        });
    }

    const save = (e?: FormEvent): void => {
        if (e) e.preventDefault();
        setIsLoading(true);

        api.putProduct(product).then(({ data }) => {
            setIsLoading(false);
            setChangesMade(false);
            if (file) {
                api.putProductFile(data.id, file).then(() => {
                    setFile(undefined);
                    toast(__('Product saved succesfully'));
                    history.push('/admin/products');
                });
            } else {
                toast(__('Product saved succesfully'));
                history.push('/admin/products');
            }
        }).catch((err) => {
            setErrors(err.response.data.errors);
            setIsLoading(false);
            toast(__('Something went wrong', ''), 'error')
        });
    }

    return (<>
        <ChangeHandler
            changesMade={changesMade}
            onCancel={() => fetch()}
            onSave={() => save()}
        />
        <PageHeader
            breadcrumb={{
                '/admin/settings': __('Settings', 'drawer'),
                '/admin/products': __('Products'),
                [`/admin/products/${id ? `${id}/edit` : 'create'}`]: id ? product.name || 'Nuovo' : 'Nuovo',
            }}
            title={`${id ? 'Modifica' : 'Nuovo'} prodotto`}
        />
        <Form onSubmit={(e: FormEvent) => save(e)}>
            <Grid.Row>
                <Grid.Column md={8}>
                    <Segment isLoading={isLoading}>
                        <div style={{ marginBottom: '1.25rem' }}>
                            <Checkbox
                                checked={product.isActive !== false}
                                label={__('Available')}
                                onChange={({ checked }: any) => handleInput({ name: 'isActive', value: checked })}
                            />
                            {product.isActive === false && (
                                <Form.Textarea
                                    label={__('Optional message')}
                                    name="availableMessage"
                                    onChange={handleInput}
                                    rows={4}
                                    style={{ marginTop: '.5rem' }}
                                    value={product.availableMessage || ''}
                                />
                            )}
                        </div>
                        <Grid.Row>
                            <Grid.Column md={9}  style={{ marginBottom: '1rem' }}>
                                <Form.Input
                                    error={errors.name}
                                    label={__('Brand name')}
                                    name="name"
                                    onChange={handleInput}
                                    required
                                    value={product.name || ''}
                                />
                            </Grid.Column>
                            <Grid.Column md={3}  style={{ marginBottom: '1rem' }}>
                                <Form.Input
                                    error={errors.form}
                                    label={__('Form')}
                                    name="form"
                                    onChange={handleInput}
                                    value={product.form || ''}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Form.Input
                            error={errors.generic_name}
                            label={__('Generic name')}
                            name="genericName"
                            onChange={handleInput}
                            value={product.genericName || ''}
                        />
                        <div style={{ background: '#f7f7f7', padding: 10, marginBottom: '1.25rem' }}>
                            <Form.Dropdown
                                label={__('Available for (empty = available for all)')}
                                autoComplete={handleHospitalAutoComplete}
                                onChange={({ value }: any) => handleHospital(value)}
                            />
                            {product.hospitals?.map((o, i) => (
                                <div className={styles.hospital} key={`h-${i}`}>
                                    {o.name}
                                    <Button
                                        icon={faTrashAlt as any}
                                        onClick={() => removeHospital(o.id)}
                                        trigger
                                    />
                                </div>
                            ))}
                        </div>
                        <Form.Input
                            label={__('Link to info/calculator')}
                            name="link"
                            placeholder="https://"
                            onChange={handleInput}
                            value={product.link || ''}
                        />
                        <Form.Input
                            accept="application/pdf"
                            label={__('Info/calculator PDF')}
                            type="file"
                            onChange={({ files }: any) => {
                                setFile(files[0]);
                                setChangesMade(true);
                            }}
                        />
                        {product.file && (
                            <Segment padding="dense" onClick={() => deleteFile()} style={{ border: 'solid 1px #e5e5e5', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <span>{product.fileName || 'No filename specified'}</span>
                                <Button
                                    icon={faTrashAlt as any}
                                    label={__('Delete', '')}
                                />
                            </Segment>
                        )}
                        <Form.Textarea
                            label={__('Information')}
                            name="information"
                            onChange={handleInput}
                            rows={4}
                            value={product.information || ''}
                        />

                        <Form.Dropdown
                            label={__('Users to notify')}
                            multiple
                            name="users"
                            options={users.map((o) => ({
                                text: `${o.firstName} ${o.lastName}`,
                                value: o.id
                            }))}
                            onChange={handleInput}
                            value={product.users || []}
                            // required
                        />

                        <div className={styles.dosageContainer}>
                            <h3>{__('Dosage(s)')}</h3>
                            <Button
                                icon={faPlus as any}
                                label={__('Add dosage')}
                                onClick={addDosage}
                                primary
                            />
                        </div>
                        {product.dosages && product.dosages.map((dosage, index) => (
                            <div key={`dos-${index}`} className={styles.dosage}>
                                <div>
                                    <Grid.Row>
                                        <Grid.Column md={5} style={{ marginBottom: '1rem' }}>
                                            <Form.Input
                                                label={__('Material number')}
                                                name="materialNumber"
                                                onChange={(data: any) => handleDosageInput(index, data)}
                                                value={dosage.materialNumber || ''}
                                            />
                                        </Grid.Column>
                                        <Grid.Column md={4}>
                                            <Form.Input
                                                label={__('Dosage')}
                                                name="dosage"
                                                onChange={(data: any) => handleDosageInput(index, data)}
                                                value={dosage.dosage || ''}
                                            />
                                        </Grid.Column>
                                        <Grid.Column md={3}>
                                            <Form.Input
                                                label={__('Max. amount')}
                                                name="maxAmount"
                                                onChange={(data: any) => handleDosageInput(index, data)}
                                                value={dosage.maxAmount}
                                                type="number"
                                                info={__('0 = normal limit')}
                                                max={8}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column md={4}  style={{ marginBottom: '1rem' }}>
                                            <Form.Dropdown
                                                options={[{
                                                    text: 'A',
                                                    value: 'A',
                                                }, {
                                                    text: 'B',
                                                    value: 'B',
                                                }]}
                                                label={__('Classification')}
                                                name="classification"
                                                onChange={(data: any) => handleDosageInput(index, data)}
                                                value={dosage.classification}
                                            />
                                        </Grid.Column>
                                        <Grid.Column md={6}  style={{ marginBottom: '1rem' }}>
                                            <Form.Input
                                                error={errors.commercializationDate}
                                                label={__('Commercialization date')}
                                                name="commercializationDate"
                                                onChange={(data: any) => handleDosageInput(index, data)}
                                                type="date"
                                                value={dosage.commercializationDate || ''}
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                </div>
                                <Button
                                    icon={faTrashAlt as any}
                                    onClick={() => removeDosage(index)}
                                    trigger
                                />
                            </div>
                        ))}
                    </Segment>
                </Grid.Column>
                <Grid.Column md={4}>
                    <Segment>
                        <h2>{__('Limit availability')}</h2>
                        <p>{__('Fill in the last 3 numbers or the full registration number to white- or blacklist. One per line.')}</p>
                        <Form.Textarea 
                            label={__('Whitelist (only available for)')}
                            rows={5}
                            name="whitelist"
                            onChange={handleInput}
                            value={product.whitelist || ''}
                        />
                        <Form.Textarea 
                            label={__('Blacklist (not available for)')}
                            rows={5}
                            name="blacklist"
                            onChange={handleInput}
                            value={product.blacklist || ''}
                        />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Form>
    </>);
}

export default ProductEditView;
